<template>
  
    <v-data-table
      mobile-breakpoint="60"
      :headers="store.headers"
      :items="store.checkList"
      hide-default-footer
      :hide-default-header="store.checkList <= 0"
      no-data-text="Yukarıdaki Form İle Bir Çek Girişi Yapın"
      :items-per-page="-1"
      disable-sort
    >
      <template v-slot:item.index="{ item, index }">
        {{ index + 1 }}
      </template>
      <template v-slot:item.total_price="{ item }">
        {{ item.total_price | currency }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn plain small class="text-capitalize" @click="store.deleteItem(item)"
          ><v-icon left> {{ icons.mdiDelete }} </v-icon> Sil</v-btn
        >
      </template>
    </v-data-table>

</template>
<script>
import { mdiDelete } from '@mdi/js'
import { useCollectionStore } from '../store'
export default {
  setup() {
    const store = useCollectionStore()

    return {
      store,
      icons: { mdiDelete },
    }
  },
}
</script>