<template>
  <v-text-field
    v-model="formattedValue"
    
    ref="inputRef"
    label="Çek Tahsilat Tutar"
    filled
    rounded
    class="rounded-lg"
    background-color="white"
    light
    hide-details=""
    suffix="TL"
  ></v-text-field>
</template>

<script>
import { watch } from '@vue/composition-api'
import { useCurrencyInput } from 'vue-currency-input'

export default {
  name: 'CurrencyInput',
  props: ['value'],

  setup(props) {
    const { inputRef, formattedValue, setValue } = useCurrencyInput({
      locale: 'tr-TR',
      currencyDisplay: 'hidden',
      currency: 'TRY',
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: false,
      autoSign: true,
      useGrouping: true,
      accountingSign: false,
    })

    watch(
      () => props.value,
      value => {
        setValue(value)
      },
    )
    return { inputRef, formattedValue }
  },
}
</script>