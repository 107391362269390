<template>
  <v-row align="center">
    <v-col cols="12" md="5">
      <CurrencyInput v-model="store.editedItem.total_price" :options="currencyOptions" />
    </v-col>

    <v-col cols="12" md="5">
      <DateInput v-model="store.editedItem.expiry_date" />
    </v-col>

    <v-col cols="12" md="2">
      <v-btn
        color="primary"
        depressed
        @click="store.addItem()"
        style="height: 56px !important"
        class="rounded-lg"
        block
      >
        <v-icon class="ml-n3 mr-3">
          {{ icons.mdiPlusCircle }}
        </v-icon>
        Ekle
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { ref } from '@vue/composition-api'

import { useCollectionStore } from '../store'

import CurrencyInput from '@/components/CurrencyInput.vue'
import DateInput from '@/components/DateInput.vue'
import { mdiPlusCircle } from '@mdi/js'
export default {
  components: { CurrencyInput, DateInput },
  setup() {
    const store = useCollectionStore()

    const currencyOptions = {
      locale: 'tr-TR',
      currencyDisplay: 'hidden',
      currency: 'TRY',
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      autoDecimalDigits: false,
      autoSign: true,
      useGrouping: true,
      accountingSign: false,
    }

    return {
      store,

      currencyOptions,
      icons: { mdiPlusCircle },
    }
  },
}
</script>