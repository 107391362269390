<template>
  <v-dialog v-model="pinia.addImageDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card tile color="grey">
      <v-toolbar tile flat color="chip">
        <v-toolbar-title>Çek Görsel Yükleme</v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-container>
        <v-row>
          <v-col>
            <v-card :disabled="pinia.imageLoading" outlined rounded="xl" class="mx-auto mt-16" width="600">
              <v-window v-model="pinia.slide" vertical>
                <v-window-item v-for="(item, index) in checkUploadList" :key="index">
                  <v-card-title class="py-9">
                    {{ item.name }}
                    <v-spacer />
                    <small>
                      {{ item.amount | currency() }} |
                      {{ item.date.slice(0, 11) }}
                    </small>
                  </v-card-title>
                  <v-divider />
                  <v-card-text class="pa-0">
                    <v-file-input
                      v-model="item.file"
                      prepend-icon=""
                      filled
                      height="75"
                      hide-details
                      :clearable="false"
                      accept="image/*"
                      rounded
                      class="rounded-0"
                      placeholder="Görsel seçimi yapınız"
                    >
                      <template v-slot:append>
                        <v-btn
                          :loading="pinia.imageLoading"
                          color="secondary"
                          @click="pinia.imageUpload(item)"
                          :disabled="!item.file"
                          rounded
                          >Yükle</v-btn
                        >
                      </template>
                    </v-file-input>
                  </v-card-text>
                </v-window-item>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { useCollectionStore } from '../store'

import { mdiRecord, mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'
export default {
  setup() {
    const pinia = useCollectionStore()

    const checkUploadList = ref([])
    onMounted(() => {
      pinia.checkListImageUpload.forEach(item => {
        checkUploadList.value.push({
          id: item.id,
          file: null,
          slug: 'front_image_path',
          name: 'Çek Ön Yüz',
          date: item.expiry_date,
          amount: item.total_price,
        })
        checkUploadList.value.push({
          id: item.id,
          file: null,
          slug: 'back_image_path',
          name: 'Çek Arka Yüz',
          date: item.expiry_date,
          amount: item.total_price,
        })
      })
    })

    return {
      pinia,

      checkUploadList,
      icons: {
        mdiRecord,
        mdiChevronLeft,
        mdiChevronRight,
      },
    }
  },
}
</script>
<style  scoped>
/*
.v-stepper__step {
  padding: 0px 0px;
}
.v-stepper >>> .v-stepper__step--active ~ .v-stepper__content {
  padding: 0px 0px;
  margin: 0 !important;
}
.v-stepper >>> .v-stepper__step--inactive ~ .v-stepper__content {
  padding: 0px;
  margin: 0 !important;
}
 .v-stepper__step >>> .v-stepper__step__step {
  background-color: #28243d !important;
  display: flex !important;
  height: 32px;
  min-width: 32px;
  width: 32px;
} */
</style>
