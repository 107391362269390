var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"mobile-breakpoint":"60","headers":_vm.store.headers,"items":_vm.store.checkList,"hide-default-footer":"","hide-default-header":_vm.store.checkList <= 0,"no-data-text":"Yukarıdaki Form İle Bir Çek Girişi Yapın","items-per-page":-1,"disable-sort":""},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.total_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total_price))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-capitalize",attrs:{"plain":"","small":""},on:{"click":function($event){return _vm.store.deleteItem(item)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]),_vm._v(" Sil")],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }