<template>
  <v-menu v-model="menu" :close-on-content-click="false" min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="model"
        label="Vade Tarih"
        filled
        
        rounded
        class="rounded-lg"
        background-color="white"
        light
        hide-details
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="model" :min="new Date().toISOString().slice(0, 10)" @input="menu = false" no-title scrollable></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ['value'],

  data() {
    return {
      menu: false,
      model: this.value,
    }
  },
  watch: {
    model(currentValue) {
      this.$emit('input', currentValue)
    },
    value(defaultValue) {
      if (defaultValue === null) {
        this.model = defaultValue
      }
    },
  },
}
</script>