<template>
  <v-row>
    <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly">
      <customer-list />
    </v-col>
    <v-col v-if="!isCustomer">
      <v-alert text outlined prominent class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0" type="warning">
        <div>Lütfen yukarıdan bir müşteri seçin</div>
      </v-alert>
    </v-col>
    <v-col v-else class="d-block d-md-flex justify-center mt-n6 mt-md-0 pt-0 pb-6 py-md-16">
      <v-card
        :disabled="pinia.loading"
        :rounded="$vuetify.breakpoint.mdAndUp ? 'xl' : '0'"
        :width="$vuetify.breakpoint.mdAndUp ? 900 : '100%'"
      >
        <v-card-title class="grey">
          <v-icon :size="$vuetify.breakpoint.mdAndUp ? '60' : '30'" left>
            {{ icons.mdiCashPlus }}
          </v-icon>
          <span :class="$vuetify.breakpoint.mdAndUp ? 'text-h5' : 'text-body-1'">Çek Tahsilat</span>
          <v-spacer />
          <v-btn
            v-if="pinia.calculate !== null"
            rounded
            color="chip"
            :x-large="$vuetify.breakpoint.mdAndUp"
            @click="pinia.calculate = null"
            class="text-capitalize text-md-body-1"
          >
            <v-icon class="mr-3 ml-n3" v-if="$vuetify.breakpoint.mdAndUp">
              {{ icons.mdiCalculatorVariant }}
            </v-icon>
            Tekrar Hesapla
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-6 pt-9" v-if="pinia.calculate === null">
          <v-row>
            <v-col>
              <v-autocomplete
                label="Fatura Seç"
                v-model="pinia.invoices"
                :items="invoiceList"
                :loading="invoicesLoading"
                multiple
                deletable-chips
                hide-no-data
                chips
                filled
                solo
                rounded
                class="rounded-lg"
                background-color="white"
                light
                hide-details
              >
                <template v-slot:item="data">
                  <div>
                    <v-list-item-content>
                      <v-list-item-subtitle class="d-flex pb-1">
                        <div style="width: 135px" class="font-weight-bold">Fatura No</div>
                        <div class="white--text">: {{ data.item.text }}</div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="d-flex pb-1">
                        <div style="width: 135px" class="font-weight-bold">Fatura Tutarı</div>
                        <div class="white--text">: {{ data.item.HPOSTAMNT | currency }}</div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="d-flex pb-1">
                        <div style="width: 135px" class="font-weight-bold">Fatura Tarihi</div>
                        <div class="white--text">: {{ data.item.DOCDATE.slice(0, 10) }}</div>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="d-flex pb-1">
                        <div style="width: 135px" class="font-weight-bold">Fatura Vadesi</div>
                        <div class="white--text">: {{ data.item.DUEDATE.slice(0, 10) }}</div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-divider />
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="mx-n9">
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <AddItem />
          <v-row class="mx-n9">
            <v-col>
              <v-divider />
            </v-col>
          </v-row>
          <v-row class="mx-n9 mt-n6">
            <v-col>
              <DataList />
            </v-col>
          </v-row>
          <v-row v-if="pinia.checkList.length > 0">
            <v-col>
              <v-btn
                block
                rounded
                color="secondary"
                @click="pinia.calculation()"
                style="height: 56px !important"
                x-large
              >
                <v-icon class="mr-3 ml-n3">
                  {{ icons.mdiCalculatorVariant }}
                </v-icon>
                Hesapla</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-row class="mx-n8 mt-n7">
            <v-col>
              <v-alert
                class="py-6 ma-0"
                tile
                :type="pinia.calculate.error === 2 ? 'warning' : 'success'"
                text
                :prominent="$vuetify.breakpoint.mdAndUp"
              >
                <span class="text-caption text-md-body-2">
                  {{ pinia.calculate.msg }}
                </span>
              </v-alert>
              <v-divider class="my-0 py-0" />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card outlined rounded="lg" light class="overflow-hidden">
                <v-card-title style="background-color: #fafafa">
                  <div class="night--text text-h6 font-weight-bold">
                    <v-icon color="night" class="mr-1 ml-n3" size="48">
                      {{ icons.mdiCheckbook }}
                    </v-icon>
                    Çekler
                  </div>
                </v-card-title>
                <v-divider class="mb-3 mb-md-0" />
                <v-data-table
                  :headers="checkHeaders"
                  :items="pinia.calculate.checks"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:item.check_no="{ index }">
                    <v-chip
                      color="night"
                      :small="!$vuetify.breakpoint.mdAndUp"
                      label
                      class="d-flex align-center justify-center"
                    >
                      {{ index + 1 }}
                    </v-chip>
                  </template>
                  <template v-slot:item.expiry_date="{ item }">
                    <span class="text-md-subtitle-1 nightDarkTon--text">
                      {{ item.expiry_date | moment('DD-MM-YYYY') }}
                    </span>
                  </template>

                  <template v-slot:item.total_price="{ item }">
                    <span class="text-md-subtitle-1 nightDarkTon--text font-weight-bold">
                      {{ item.total_price | currency }}
                    </span>
                  </template>
                </v-data-table>

                <v-divider />
                <v-card-text class="px-4">
                  <v-row dense>
                    <v-col cols="12" md="4">
                      <v-card color="primary" outlined>
                        <v-card-text>
                          <div class="text-subtitle-2" style="color: #524d71">Çek Adet</div>

                          <div class="text-h6 font-weight-bold nightDark--text">
                            {{ pinia.calculate.checks.length }} Adet
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-card color="primary" outlined>
                        <v-card-text>
                          <div class="text-subtitle-2" style="color: #524d71">Çek Toplam Tutar</div>
                          <div class="text-h6 font-weight-bold nightDark--text">
                            {{ pinia.calculate.checkGenAmount | currency }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-card color="primary" outlined>
                        <v-card-text>
                          <div class="text-subtitle-2" style="color: #524d71">Çek Ortalama Vade</div>
                          <div class="text-h6 font-weight-bold nightDark--text">
                            {{ pinia.calculate.checkGenAvgDate | moment('DD-MM-YYYY') }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-card outlined rounded="lg" light class="overflow-hidden">
                <v-card-title style="background-color: #fafafa">
                  <div class="night--text text-h6 font-weight-bold">
                    <v-icon color="night" class="mr-1 ml-n3" size="48">
                      {{ icons.mdiInvoiceList }}
                    </v-icon>
                    Faturalar
                  </div>
                </v-card-title>
                <v-divider class="mb-3 mb-md-0" />
                <v-data-table
                  :headers="invoiceHeaders"
                  :items="pinia.calculate.openInvoices"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:item.invoice_no="{ item }">
                    <v-chip
                      color="night"
                      :small="!$vuetify.breakpoint.mdAndUp"
                      label
                      class="d-flex align-center justify-center"
                    >
                      {{ item.salinvnum }} - {{ item.salinvtype }}
                    </v-chip>
                  </template>
                  <template v-slot:item.due_date="{ item }">
                    <span class="text-md-subtitle-1 nightDarkTon--text">
                      {{ item.due_date | moment('DD-MM-YYYY') }}
                    </span>
                  </template>
                  <template v-slot:item.invoice_date="{ item }">
                    <span class="text-md-subtitle-1 nightDarkTon--text">
                      {{ [item.invoice_date, 'DD.MM.YYYY'] | moment('DD-MM-YYYY') }}
                    </span>
                  </template>
                  <template v-slot:item.amount="{ item }">
                    <span class="text-md-subtitle-1 nightDarkTon--text font-weight-bold">
                      {{ item.amount | currency }}
                    </span>
                  </template>
                </v-data-table>

                <v-divider />
                <v-card-text class="px-4">
                  <v-row dense>
                    <v-col cols="12" md="4">
                      <v-card color="primary" outlined>
                        <v-card-text>
                          <div class="text-subtitle-2" style="color: #524d71">Fatura Adet</div>

                          <div class="text-h6 font-weight-bold nightDark--text">
                            {{ pinia.calculate.openInvoices.length }} Adet
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-card color="primary" outlined>
                        <v-card-text>
                          <div class="text-subtitle-2" style="color: #524d71">Fatura Toplam Tutar</div>
                          <div class="text-h6 font-weight-bold nightDark--text">
                            {{ pinia.calculate.openInvoiceAmount | currency }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-card color="primary" outlined>
                        <v-card-text>
                          <div class="text-subtitle-2" style="color: #524d71">Fatura Ortalama Vade</div>
                          <div class="text-h6 font-weight-bold nightDark--text">
                            {{ pinia.calculate.invoiceCalcedAvgDate.date | moment('DD-MM-YYYY') }}
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-card
                :color="
                  pinia.calculate.invoiceCalcedAvgDate.days == 0
                    ? 'success'
                    : pinia.calculate.invoiceCalcedAvgDate.days > 0
                    ? 'error'
                    : 'warning'
                "
                outlined
              >
                <v-card-text>
                  <div class="text-subtitle-2 white--text">
                    Tahsilat {{ pinia.calculate.invoiceCalcedAvgDate.days == 0 ? 'Tamam' : 'Gecikme' }}
                  </div>

                  <div class="text-h6 font-weight-bold white--text">
                    {{ Math.round(pinia.calculate.invoiceCalcedAvgDate.days) }} Gün
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="8">
              <v-textarea
                label="Açıklama"
                filled
                v-model="pinia.description"
                rounded
                class="rounded-lg"
                background-color="white"
                light
                hide-details=""
                rows="3"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                color="secondary"
                @click="pinia.sendCheck()"
                x-large
                class="rounded-lg text-capitalize"
                style="height: 60px !important"
                block
                >Gönder</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <AddImage v-if="pinia.checkListImageUpload.length > 0" />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// Store
import store from '@/store'
import { useCollectionStore } from '../store'

// Component
import AddItem from './AddItem.vue'
import AddImage from './AddImage.vue'
import DataList from './DataList.vue'
import CustomerList from '@/components/CustomerList.vue'

// Utils
import { postData } from '@utils'
import { useToast } from 'vue-toastification/composition'

// Icons
import { mdiCashPlus, mdiCalculatorVariant, mdiInvoiceList, mdiCheckbook } from '@mdi/js'
import { ref, onMounted, watch, computed } from 'vue-demi'

export default {
  components: { AddItem, DataList, AddImage, CustomerList },

  setup() {
    const toast = useToast()
    const isCustomer = ref(false)

    // Müşteri Kontrol
    watch(
      () => store.state.plasiyerCustomer,
      val => {
        if (val) {
          isCustomer.value = true
          invoiceOpenCustomerFetch()
        } else {
          isCustomer.value = false
        }
      },
    )
    const invoicesLoading = ref(false)
    const invoiceList = ref([])
    const invoiceOpenCustomerFetch = () => {
      invoicesLoading.value = true
      pinia.emptyData()
      pinia.checkListImageUpload = []
      invoiceList.value = []
      postData({
        method: 'getPlasiyerRegisterInvoice',
      })
        .then(response => {
          // API dönüşündeki hatalara bakıyoruz
          if (response.error) {
            throw new Error(response.msg)
          }

          if (typeof response.result === 'string') {
            return
          }

          response.result.map((item, index) => {
            invoiceList.value.push({
              text: item.SALINVNUM + '-' + item.SALINVTYPE,
              value: item.SALINVNUM + '-' + item.SALINVTYPE,
              HPOSTAMNT: item.HPOSTAMNT,
              DOCDATE: item.DOCDATE,
              DUEDATE: item.DUEDATE,
            })
          })
        })
        .catch(e => {
          // API hataya girdi
          invoicesLoading.value = false
          toast.error(e.message, {
            position: 'bottom-center',
          })
        })
        .finally(() => {
          invoicesLoading.value = false
          // API işlemi bittikten sonra
        })
    }

    onMounted(() => {
      if (store.state.plasiyerCustomer) {
        isCustomer.value = true
        invoiceOpenCustomerFetch()
      }
    })

    const pinia = useCollectionStore()

    const invoicesSum = computed(() => {
      return 500
    })
    const invoiceHeaders = [
      {
        text: 'No',
        value: 'invoice_no',
        width: 150,
        sortable: false,
      },
      {
        text: 'Vade Tarihi',
        value: 'due_date',
        width: 200,
        sortable: false,
      },
      {
        text: 'Fatura Tarihi',
        value: 'invoice_date',
        width: 200,
        sortable: false,
      },
      {
        text: 'Tutar',
        value: 'amount',
        align: 'end',
        sortable: false,
      },
    ]

    const checkHeaders = [
      {
        text: 'No',
        value: 'check_no',
        width: 150,
        sortable: false,
      },

      {
        text: 'Çek Tarihi',
        value: 'expiry_date',
        width: 200,
        sortable: false,
      },
      {
        text: 'Tutar',
        value: 'total_price',
        align: 'end',
        sortable: false,
      },
    ]

    const remove = item => {
      const index = pinia.invoices.indexOf(item.name)
      if (index >= 0) pinia.invoices.splice(index, 1)
    }

    return {
      remove,
      invoicesLoading,
      invoiceHeaders,
      checkHeaders,
      pinia,
      isCustomer,
      invoiceList,
      invoicesSum,
      icons: { mdiCashPlus, mdiInvoiceList, mdiCalculatorVariant, mdiCheckbook },
    }
  },
}
</script>

<style>
</style>